import React, { useState, useEffect } from "react"
import { OutlinedArrowButton } from "../common/outlineArrowButton"
import emailjs from "@emailjs/browser"
import { useNavigate } from "react-router-dom"

const LetsConnect = () => {
  const navigate = useNavigate()
  const [selectedHelps, setSelectedHelps] = useState([]) // State to store selected chips
  const [isLoading, setIsLoading] = useState(false) // State to track loading
  const [name, setName] = useState("") // State for name input
  const [email, setEmail] = useState("") // State for email input
  const [isNameValid, setIsNameValid] = useState(false) // State for name validation
  const [isEmailValid, setIsEmailValid] = useState(false) // State for email validation
  const [formAttempted, setFormAttempted] = useState(false) // State to track if form submission was attempted

  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

  useEffect(() => {
    if (formAttempted) {
      setIsNameValid(name.trim() !== "")
      setIsEmailValid(emailRegex.test(email))
    }
  }, [name, email, formAttempted])

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const toggleHelpSelection = (help) => {
    setSelectedHelps((prevSelected) =>
      prevSelected.includes(help)
        ? prevSelected.filter((h) => h !== help)
        : [...prevSelected, help]
    )
  }

  const data = [
    { help: "Social media" },
    { help: "Brand Identity" },
    { help: "Content creation" },
    { help: "Marketing" },
    { help: "Website Design" },
    { help: "Logo Design" },
    { help: "Other" },
  ]

  const handleSubmit = (e) => {
    e.preventDefault()

    const isNameValidNow = name.trim() !== ""
    const isEmailValidNow = emailRegex.test(email)

    if (!isNameValidNow || !isEmailValidNow) {
      setFormAttempted(true)
      setIsNameValid(isNameValidNow)
      setIsEmailValid(isEmailValidNow)
      return
    }

    setFormAttempted(true)
    setIsLoading(true)

    try {
      const formData = {
        name,
        company: e.target.company.value,
        email,
        contact: e.target.contact.value,
        helps: selectedHelps.join(", "),
      }

      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          formData,
          {
            publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
          }
        )
        .then(
          (response) => {
            sessionStorage.setItem("formSubmitted", "true")
            navigate("/thank-you")
            setIsLoading(false)
            setTimeout(() => {
              setFormAttempted(false)
              setName("")
              setEmail("")
              setSelectedHelps([])
              e.target.company.value = ""
              e.target.contact.value = ""
            }, 3000)
          },
          (error) => {
            setIsLoading(false)
          }
        )
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <div className="relative flex flex-col xl:flex-row justify-center gap-12 pt-40 xl:pt-52 px-5 xl:px-40">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col xl:flex-row justify-center gap-12"
      >
        <div className="flex flex-col flex-wrap">
          <h1 className="text-lightBlack text-center xl:text-start text-3xl xl:text-7xl font-bold text-wrap">
            Glad you are Here!
          </h1>
          <h2 className="text-themeBlue text-center xl:text-start mt-2 xl:mt-0 text-lg xl:text-2xl font-medium">
            Drop in some quick details, we’re excited to know more about you!
          </h2>
        </div>
        <div className="w-[1px] bg-lightBlack hidden xl:block"></div>
        <div className="flex flex-col justify-start items-start gap-6">
          <div className="flex flex-col gap-1 w-full">
            <label
              htmlFor="yourName"
              className="font-bold text-lg text-lightBlack text-left"
            >
              Your Name:
            </label>
            <input
              type="text"
              id="yourName"
              value={name}
              onChange={handleNameChange}
              placeholder="What would you like us to call you?"
              className={`w-full xl:w-[640px] placeholder:text-[#9B9B9B] placeholder:text-sm outline-none border-[1px] border-lightBlack/50 rounded-[48px] py-2 px-4 ${
                formAttempted && !isNameValid ? "border-red-500" : ""
              }`}
            />
            {formAttempted && !isNameValid && (
              <p className="text-red-500  pl-4 text-sm mt-1">
                Name is required.
              </p>
            )}
          </div>
          <div className="flex flex-col gap-1 w-full">
            <label
              htmlFor="company"
              className="font-bold text-lg text-lightBlack text-left"
            >
              Company’s Name:
            </label>
            <input
              type="text"
              id="company"
              name="company"
              placeholder="Which place do you represent?"
              className="w-full xl:w-[640px] placeholder:text-[#9B9B9B] placeholder:text-sm outline-none border-[1px] border-lightBlack/50 rounded-[48px] py-2 px-4"
            />
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-bold text-lg text-lightBlack text-left">
              What do you need help with:
            </p>
            <div className="flex justify-start gap-3 flex-wrap mt-2 xl:mt-0 max-w-[90%]">
              {data.map((e) => (
                <div
                  key={e.help}
                  onClick={() => toggleHelpSelection(e.help)} // Toggle selection on click
                  className={`bg-[#E8E8E8] text-lightBlack text-sm xl:text-base text-center font-normal rounded-[36px] px-3 py-1 cursor-pointer ${
                    selectedHelps.includes(e.help)
                      ? "bg-themeBlue text-white"
                      : ""
                  }`}
                  style={{ userSelect: "none" }}
                >
                  {e.help}
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col w-full xl:flex-row gap-3">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="email"
                className="font-bold text-lg text-lightBlack text-left"
              >
                Email:
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="How can we reach you?"
                className={`w-full xl:w-[315px] placeholder:text-[#9B9B9B] placeholder:text-sm outline-none border-[1px] border-lightBlack/50 rounded-[48px] py-2 px-4 ${
                  formAttempted && !isEmailValid ? "border-red-500" : ""
                }`}
                pattern={emailRegex.source}
              />
              {formAttempted && !isEmailValid && (
                <p className="text-red-500 pl-4 text-sm mt-1">
                  Please enter a valid email address.
                </p>
              )}
            </div>
            <div className="flex flex-col gap-1 ">
              <label
                htmlFor="contact"
                className="font-bold text-lg text-lightBlack text-left"
              >
                Contact:
              </label>
              <input
                type="tel"
                id="contact"
                placeholder="Sometimes, this is the best way"
                className="w-full xl:w-[315px] placeholder:text-[#9B9B9B] placeholder:text-sm outline-none border-[1px] border-lightBlack/50 rounded-[48px] py-2 px-4"
              />
            </div>
          </div>
          <div className="flex xl:block justify-center w-full">
            <OutlinedArrowButton
              buttonText={"Let's Connect"}
              type="submit"
              className={"xl:px-6 py-2 md:px-4"}
              showLoader={isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default LetsConnect
