import React from "react"
import copyIcon from "../assets/🦆 icon _file copy_.svg"
import logo from "../assets/Slashon Logos/slashon.agency_white_logo.svg"
import { useNavigate, Link } from "react-router-dom"
import insta from "../assets/Footer/jam_instagram.svg"
import linkedin from "../assets/Footer/jam_linkedin-square.svg"
import RouteNames from "../RouteNames"

const Footer = () => {
  const navigate = useNavigate()

  const listData = [
    { title: "Home", path: RouteNames.HOME },
    { title: "About", path: RouteNames.ABOUT_US },
    { title: "Solutions", path: RouteNames.SOLUTIONS },
    { title: "Projects", path: RouteNames.PROJECTS },
    { title: "Contact Us", path: RouteNames.CONTACT_US },
  ]

  const socialLinks = [
    { title: "Instagram", url: "https://www.instagram.com/weareslashon" },
    {
      title: "LinkedIn",
      url: "https://www.linkedin.com/company/slashon-agency",
    },
  ]

  const AnimatedLink = ({ path, children, external = false, target = "" }) => {
    const LinkComponent = external ? "a" : Link
    const linkProps = external ? { href: path } : { to: path }

    const handleClick = (event, target) => {
      if (!external) {
        event.preventDefault()
        navigate(path)
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          })
        }, 100)
      } else {
        event.preventDefault()
        window.open(target, "_blank")
      }
    }

    return (
      <LinkComponent
        {...linkProps}
        className="mb-4 relative inline-block group"
        onClick={(event) => handleClick(event, target)}
      >
        <span className="relative inline-block overflow-hidden">
          {children}
          <span className="absolute left-0 bottom-0 w-[120%] h-[1px] bg-white -translate-x-full group-hover:animate-underline-bounce"></span>
        </span>
      </LinkComponent>
    )
  }

  return (
    <div className="bg-themeBlue rounded-t-[50px] px-5 xl:px-24 pt-10">
      <div className="xl:hidden text-white text-center mb-10 text-2xl font-bold">
        <p>Follow us on</p>
        <div className="flex items-center justify-center gap-3 mt-2">
          <a
            href={socialLinks[0].url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={insta}
              alt="Instagram"
              className="scale-125"
              loading="lazy"
            />
          </a>
          <a
            href={socialLinks[1].url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={linkedin}
              alt="LinkedIn"
              className="scale-125"
              loading="lazy"
            />
          </a>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="text-white font-bold space-y-4 text-sm xl:text-lg max-w-[60%]">
          <u
            onClick={() => {
              window.location.href =
                "mailto:hey@slashon.agency?cc=kartik@slashon.agency"
            }}
          >
            hey@slashon.agency
          </u>
          <div className="flex">
            <u>
              1st floor, 264-265, Dr. Annie Besant Rd, Worli,
              <br /> Mumbai, Maharashtra, 400051
            </u>
          </div>
          <div
            className="flex items-center gap-2"
            onClick={() => {
              const isMobile = /iPhone|iPad|iPod|Android/i.test(
                navigator.userAgent
              )
              if (isMobile) {
                window.location.href = "tel:+916359541927"
              } else {
                navigator.clipboard
                  .writeText("+91 6359541927")
                  .then(() => {
                    alert("Contact number copied to clipboard!")
                  })
                  .catch((err) => {
                    console.error("Could not copy text: ", err)
                  })
              }
            }}
          >
            <u>+91 6359541927</u>
            <img src={copyIcon} alt="" loading="lazy" />
          </div>
        </div>
        <div className="text-white font-bold text-sm xl:text-lg flex justify-center gap-20">
          <div className="flex flex-col mr-8 md:mr-20 xl:mr-0">
            {listData.map((item) => (
              <AnimatedLink key={item.title} path={item.path}>
                {item.title}
              </AnimatedLink>
            ))}
          </div>
          <div className="hidden xl:flex xl:flex-col">
            {socialLinks.map((item) => (
              <AnimatedLink
                key={item.title}
                path={item.url}
                external
                target={item.url}
              >
                {item.title}
              </AnimatedLink>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col xl:flex-row justify-between items-center xl:items-end py-3 mt-5 xl:mt-0">
        <img
          src={logo}
          alt=""
          loading="lazy"
          className=" xl:h-[212px] mb-5 xl:mb-0"
        />
        <div className="text-white font-bold text-sm xl:text-lg flex justify-center xl:justify-between w-full xl:w-fit items-center gap-2 xl:-mr-20">
          <p onClick={() => navigate("/")} className="cursor-pointer">
            © Slashon 2024
          </p>
          <div className="h-1.5 w-1.5 bg-white rounded-full"></div>
          <p onClick={() => navigate("/tnc")} className="cursor-pointer">
            Terms & Privacy Policy
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
