import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Solutions from "./pages/Solutions";
import Projects from "./pages/Projects";
import ContactUs from "./pages/ContactUs";
import TnC from "./pages/TnC";
import ProjectDetails from "./pages/ProjectSection";
import ErrorRoute from "./components/ErrorRoute";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import CustomCursor from "./components/common/CustomCursor";
import ScrollToTop from "./components/common/ScrollToTop";
import RouteNames from "./RouteNames";
import Thanks from "./pages/Thanks";

const App = () => {
  return (
    <div className="">
      <CustomCursor />
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path={RouteNames.HOME} element={<Home />} />
          {/* About */}
          <Route
            path="/about"
            element={<Navigate to={RouteNames.ABOUT_US} />}
          />
          <Route path={RouteNames.ABOUT_US} element={<AboutUs />} />
          {/* Solutions */}
          <Route path={RouteNames.SOLUTIONS} element={<Solutions />} />
          {/* Projects */}
          <Route path={RouteNames.PROJECTS} element={<Projects />} />
          <Route
            path={RouteNames.PROJECT_DETAILS(":projectPath")}
            element={<ProjectDetails />}
          />
          {/* Contact */}
          <Route
            path="/contact"
            element={<Navigate to={RouteNames.CONTACT_US} />}
          />
          {/* Contact Form Submitted */}
          <Route
            path="/thank-you"
            element={<Thanks />}
          />
          <Route path={RouteNames.CONTACT_US} element={<ContactUs />} />
          {/* TnC */}
          <Route path={RouteNames.TNC} element={<TnC />} />
          {/* Unknown Route */}
          <Route path={RouteNames.ERROR} element={<ErrorRoute />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
