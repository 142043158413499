import React, { useEffect } from "react"
import { motion } from "framer-motion"
import circle from "./../assets/ContactUs/circularCorrect.svg"
import diamond from "./../assets/ContactUs/diamond.svg"
import { useNavigate } from "react-router-dom"

const Thanks = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const formSubmitted = sessionStorage.getItem("formSubmitted")

    if (!formSubmitted) {
      navigate("/")
    }

    sessionStorage.removeItem("formSubmitted")
  }, [navigate])

  return (
    <motion.div
      className="flex flex-col h-screen items-center justify-center"
      initial={{ opacity: 0.2, scale: 0.6 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="relative my-8">
        <img
          src={circle}
          alt="Logo"
          className="w-20 h-20 xl:w-36 xl:h-36"
          loading="lazy"
        />

        <img
          src={diamond}
          alt="Diamond Top Left"
          className="absolute w-6 h-6 top-[-12px] left-[-12px] xl:w-10 xl:h-10 xl:top-[-20px] xl:left-[-20px]"
          loading="lazy"
        />

        <img
          src={diamond}
          alt="Diamond Bottom Right"
          className="absolute w-6 h-6 bottom-[-12px] right-[-12px] xl:w-10 xl:h-10 xl:bottom-[-20px] xl:right-[-20px] "
          loading="lazy"
        />
      </div>

      <p className="text-black text-center text-xl md:text-2xl xl:text-4xl font-semibold mt-4 tracking-[-0.5px]">
        Thank you for reaching out!
      </p>
      <p className="text-black text-center text-md md:text-lg xl:text-2xl font-normal tracking-[-1px]">
        Our team will get back to you shortly
      </p>
    </motion.div>
  )
}

export default Thanks
